/* You can add global styles to this file, and also import other style files */

// Tailwind SCSS Import 
@tailwind base;
@tailwind components;
@tailwind utilities;

//Icons
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";

@import '../node_modules/jsoneditor/dist/jsoneditor.min.css';

//Navigation 
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';

// Input
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';

// Button
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';

// Dropdown 
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';

//list
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';

// Table
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';

// Calendar
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';

// Notification
@import '../node_modules/@syncfusion/ej2-angular-notifications/styles/material.css';
@import './../public/SCSS/common.scss';
@import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/regular.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/solid.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';
