@import './config/variables';

:root[theme=dark] {
  --theme-background: #222226;
  --theme-color: #fff;
  --border-color: #fff;
  --card-color: #2F2F2F;
  --background-field: #4c4a4a;
  --table-color:#212529;
  --table-header: #2B3035;
  --table-border: #495057;
}

body {
    font-family: "Inter", sans-serif !important;
    background-color: var(--theme-background, #f9f9fe);
}

html, body {
    height: 100%;
}
.page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    app-footer {
        margin-top: auto;
    }
}

.e-toolbar {
  background-color: var(--theme-background, #f9f9fe);
  color: var(--theme-color, #222226);
}

.e-sidebar {
  background-color: var(--theme-background, #fff);
}

.fixed-height {
    height: calc(100vh - 72px);
    overflow-y: auto;
}
.e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd) {
    background-color: var(--table-color, $da-white);
    color: var(--theme-color, $da-black) !important;
}

.fixed-sidebar-height {
    height: calc(100vh - 160px);
}
@media (min-width: 1024px) {
    .e-sidebar-overlay {
        display: none !important;
    }
}

/* Common btn css start */
@mixin btn {
    padding: 6px 20px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    border-radius: 5px
}

.btn-primary {
    @include btn;
    color: $da-white;
    background-color: $da-primary;
}

.btn-secondary {
    @include btn;
    color: $da-black;
    background-color: $da-gray-shade;
}

/* Common btn css end */
.switch_btn {
    display: flex;
    align-items: center;
    background-color: var(--background-field ,$da-gray-shade);
    border-radius: 10px;
    gap: 20px;
    font-size: 15px;
    font-weight: 500;
    padding: 12px;
    max-width: max-content;


    .e-switch-wrapper {
        &:hover {
            .e-switch-on {
                background-color: $da-primary !important;
            }
        }

        .e-switch-handle.e-switch-active,
        .e-switch-on {
            background-color: $da-primary;
        }
    }
}


.e-checkbox-wrapper {
    .e-checkbox {
        &:focus {
            + .e-frame {
                &.e-check {
                    background-color: $da-blue-shade !important;
                }
            }
        }
    }
    .e-frame {
        font-size: 10px;
        border-radius: unset;
        line-height: 11px;
        border: 1px solid $da-gray-shade-1;
    }
}

.checkbox_block {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    .e-radio-wrapper {
        width: 100%;
    }

    .e-checkbox-wrapper {
        .e-frame.e-check {
            background-color: $da-blue-shade !important;
        }
    }
}

.search_input {
    position: relative;

    ejs-autocomplete {
        border-width: 1px;
        border-radius: 20px;
        background: var(--background-field, $da-white) ;
        .e-input-group.e-control-wrapper {
            padding: 4px 12px 4px 40px;

            &.e-input-focus:before {
                left: 46%;
            }
            &.e-input-focus:after {
                right: 46%;
            }
        }
    }

    .e-control {
        &-wrapper {
            border-radius: 20px !important;
        }
    }

    &-icon {
        position: absolute;
        top: 8px;
        left: 15px;
        z-index: 9;
        cursor: pointer;
        color: $da-blue-shade;
    }
}

.radio_block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    .radio-btn {
        background: var(--background-field,$da-gray-shade);
        color: var(--border-color, $da-black);
        font-size: 13px;
        font-weight: 400;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 7px;
        cursor: pointer;
        input {
            cursor: pointer;
            height: 18px;
            width: 18px;
            accent-color: $da-blue-shade;
        }
    }
    &.radio_transparent {
        .radio-btn {
            background: transparent;
            padding: 0px;
        }
    }
}

.e-radio {
    &:checked {
        + label {
            &::before {
                border-color: $da-blue-shade !important;
            }
            &::after {
                background-color: $da-blue-shade !important;
                color: $da-blue-shade !important;
            }
            &:hover, &.e-focus {
                &::before {
                    border-color: $da-blue-shade !important;
                }
                &::after {
                    background-color: $da-blue-shade !important;
                    color: $da-blue-shade !important;
                }
            }
        }
    }
}

.e-input-group {
    margin-bottom: 0 !important;
    &.e-input-focus {
        &::after, &::before {
            background: $da-blue-shade !important;
        }
    }
    &.e-outline {
        &.e-input-focus {
            border-color: $da-blue-shade !important;
            box-shadow: unset !important;
            overflow: hidden;
        }
    }
    .e-range-icon {
        color: $da-lightpink !important;
    }
    .e-input {
        background:  var(--background-field ,$da-white) !important;
        &::selection {
            background-color: $da-blue-shade !important;
        }
        &.e-disabled {
            -webkit-text-fill-color: var(--theme-color, $da-black) !important;
        }
    }
}

.e-date-range-wrapper {
    border: 1px solid $da-gray-shade-6 !important;
    border-radius: 4px !important;
    padding: 0 10px;
    min-height: 38px;
    input {
        padding: 0 !important;
    }
    .e-input-group-icon {
        &.e-icons {
            &.e-active {
                color: $da-blue-shade !important;
            }
        }
    }
}

.e-outline {
    &.e-input-group {
        .e-input-group-icon {
            color: var(--border-color,$da-primary) !important;
        }
    }
}

.e-calendar {
    .e-content {
        td {
            &.e-today {
                span {
                    &.e-day {
                        border: 1px solid $da-blue-shade;
                        color: $da-blue-shade;
                    }
                }
            }
            &.e-focused-date {
                &.e-today {
                    span {
                        &.e-day {
                            border: 1px solid $da-blue-shade !important;
                            color: $da-blue-shade;
                        }
                    }
                }
            }
            &.e-selected {
                span {
                    &.e-day {
                        background-color: $da-blue-shade !important;
                    }
                }
            }
        }
    }
    .e-start-date, .e-end-date {
        &.e-selected {
            &.e-range-hover {
                &.e-today {
                    span {
                        &.e-day {
                            background-color: $da-blue-shade !important;
                            border: 1px solid $da-blue-shade !important;
                        }
                    }
                }
            }
        }
    }
}

.simulation-table-section, .data-chart-table-section {
    .e-grid {
        .e-gridheader {
            border-bottom: 0px;
            thead {
                .e-headercell {
                    background-color: var(--table-header, $da-blue-shade) !important;
                    border: 1px solid var(--table-border, $da-gray-shade-8) !important;
                    color: $da-white;
                    .e-headertext {
                        color: $da-white;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .e-icons {
                        color: $da-white !important;
                    }
                    .e-rhandler {
                        border-right: 0px !important;
                    }
                }
            }
        }
        .e-gridcontent {
            tbody {
                background-color: $da-gray-shade-7;
                .e-row {
                    background-color: var(--table-color, $da-white) !important;
                    &:hover {
                        .e-rowcell {
                            color: var(--theme-color,#000) !important;
                        }
                    }
                    .e-rowcell {
                        border: 1px solid var(--table-border, $da-gray-shade-8);
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
        &.e-gridhover {
            .e-row {
                &:hover {
                    .e-rowcell {
                        background-color: $da-green-shade !important;
                    }
                }
            }
        }
    }
    .e-toolbar {
        .e-toolbar-items {
            .e-toolbar-right {
                display: flex;
                gap: 10px;
                left: 0;
                position: absolute;
            }
        }
    }
}

// table css
.e-grid {
    border-radius: 10px;
    border: unset;
    .e-gridheader {
        border-radius: 10px 10px 0 0;
        border-top: 0;
        thead {
            .e-headercell {
                background-color: $da-green-shade;
                // background-color: $da-blue-shade;
                // color: $da-white;
                // border-right: 1px solid $da-white;
                // &:last-child {
                //     border-right: unset;
                // }
                .e-headertext {
                    font-size: 12px;
                    font-weight: bold;
                }
            }
            .e-filterbar {
                display: none;
            }
        }
    }
    .e-gridcontent {
        border-radius: 0 0 10px 10px;
        tbody {
            // background-color: $da-gray-shade-7;
            .e-row {
                .e-rowcell {
                    border-color: $da-gray-shade-8;
                    // border-right: 1px solid $da-gray-shade-8;
                    color: $da-black;
                    font-size: 12px;
                    &:last-child {
                        border-right: unset;
                    }
                    &.e-active {
                        background-color: $da-green-shade;
                    }
                }
            }
        }
    }
    // &.e-gridhover {
    //     .e-row {
    //         &:hover {
    //             .e-rowcell {
    //                 background-color: $da-green-shade !important;
    //             }
    //         }
    //     }
    // }
}

.chips_block {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    .e-chip-list {
        .e-chip {
            background: var(--table-header,$da-gray-shade);
            &.chip-disabled {
                cursor: not-allowed;
                &.e-active {
                    background:var(--background-field,#e0e0e0) !important;
                    &::before {
                        width: 0px !important;
                    }
                    .e-chip-text {
                        color: var(--border-color, $da-black) !important;
                    }
                }
            }
            &.e-active {
                background: $da-blue-shade;
                .e-chip-text {
                    color: var(--border-color, $da-white) !important;
                }
                &::before {
                    color: $da-white;
                }
            }
            .e-chip-text {
                font-size: 12px;
                font-weight: 500;
                color: var(--border-color, $da-black) !important;
                transition: all 0.3s;
            }
        }
    }
}

.e-textbox {
    input::placeholder {
        color: $da-gray-shade-2;
    }
}

.e-multiselect{
    .e-searcher {
        padding-left: 10px;
    }
}

.card {
    padding: 16px 14px;
    border-radius: 10px;
    width: 100%;
    background-color: var(--card-color, $da-white);
    box-shadow: $da-black-shadow;
    color: var(--theme-color, black);
    label {
        font-size: 15px;
        font-weight: 500;
        display: block;
    }
    &.fix-height {
        max-height: calc(100vh - 470px);
        height: 100%;
        padding-bottom: 30px;
        @media screen and (max-width: 1024px) {
            max-height: fit-content;
        }
    }
}

.custom-input {
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    width: 100%;
    padding-left: 5px;
    &.small-text-box {
        width: 52px;
    }
}

.refrence--data-section {
    .refrence-label {
        background-color: $da-lightpink;
        color: $da-white;
        font-size: 16px;
        border-radius: 10px 10px 0 0;
        display: inline-block;
        padding: 10px 20px;
        margin-left: 40px;
        font-weight: 600;
        @media screen and (max-width: 567px) {
            font-size: 12px;
            margin-left: 15px;;
            padding: 7px 15px;
        }
    }
    .refrence-data {
        border-radius: 10px;
        border: 2px solid $da-lightpink;
        overflow: hidden;
        textarea {
            border-radius: 10px;
            width: 100%;
            padding: 10px 20px;
            font-size: 14px;
            color: $da-gray-shade-2;
            background:  var(--background-field, $da-white) !important;
            @media screen and (max-width: 567px) {
                font-size: 12px;
            }
            &:focus-visible {
                outline: none;
            }
        }
    }
}

.data-chart-table-section {
    // border: 2px dashed $da-primary;
    .refrence-title-bar {
        background-color: var(--theme-background, $da-blue-shade);
        border-radius: 10px 10px 0 0;
        h2 {
            color: $da-white;
            font-weight: bold;
        }
        span {
            font-size: 12px;
            color: $da-white;
            background-color: $da-primary;
            border-radius: 15px;
            padding: 3px 7px;
        }
        .revenue-section {
            h6 {
                font-size: 14px;
                color: $da-white;
            }
        }
        .e-dropdown-btn {
            background-color: transparent;
            border: 1px solid $da-white;
            padding: 9px 13px;
            color: $da-white;
            border-radius: 5px;
            .e-btn-icon {
                background-color: transparent !important;
                padding: 0 !important;
                margin-left: 25px;
            }
        }
        .download-btn {
            .e-dropdown-btn {
                padding: 5px!important;
            }
        }
    }
    @media (max-width: 600px) {
        .chart-box {
            width: 520px;
        }
    }
    .revenue-list {
        .revenue-box {
            padding: 10px 0;
            border-bottom: 1px solid $da-gray-shade-9;
            &:last-child {
                border-bottom: none;
            }
            span {
                font-size: 12px;
                color: $da-lightpink;
                background-color: $da-lightpink-1;
                border: 1px solid $da-lightpink;
                font-weight: bold;
                padding: 5px 7px;
                border-radius: 5px;
                @media screen and (max-width: 567px) {
                    font-size: 10px;
                }
            }
        }
    }
    .custom-revenue {
        border-radius: 0 10px 10px 0;
        box-shadow: $da-black-shadow;
        margin-right: 5px;
        .revenue-box {
            span {
                padding: 3px !important;
                font-size: 9px !important;
                border-radius: 10px;
                font-weight: 500;
            }
        }
    }
    .chart-background {
        background-color: var(--card-color, $da-white-shade-1);
    }
}

.info-section {
    border-radius: 5px;
    background-color: $da-gray-shade;
    h6 {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    p {
        font-size: 12px;
        color: $da-gray-shade-3;
    }
}

.scenario-label {
    border-radius: 10px;
    color: $da-white;
    font-weight: 600;
    font-size: 16px;
    padding: 7px 15px;
    @media screen and (max-width: 567px) {
        font-size: 13px;
    }
}

.scenario-actual-label {
    @extend .scenario-label;
    background-color: $da-blue-shade;
}

.scenario-forecast-label {
    @extend .scenario-label;
    background-color: $da-lightpink;
}

.forecast-btn-section {
    i {
        color: $da-lightpink;
        font-size: 22px;
    }
}

.e-badge {
    display: flex;
    align-items: center;
    &-primary {
        background-color: $da-blue-shade !important;
    }
    &-secondary {
        background-color: $da-lightpink !important;
    }
}

.btn-section {
    .fa-copy {
        color: $da-blue-shade;
        font-weight: 600;
        font-size: 22px;
    }
    .fa-trash-can {
        font-size: 20px;
        font-weight: 600;
    }
}
.custom-select {
    .e-menu-wrapper {
        background: transparent;
        .e-menu-item {
            padding: 0px !important;
            height: 17px !important;
            line-height: 15px;
            display: flex;
            align-items: center;
            &.e-selected,
            &.e-focused {
                background: transparent;
                .e-menu-icon {
                    color: #16a34a !important;
                }
            }
            .e-menu-icon {
                margin: 0px;
                color: #16a34a;
                line-height: 15px;
            }
            .e-caret {
                display: none;
            }
        }
    }
}


.green-box {
    border-color: $da-primary;
    font-size: 12px;
    color: $da-gray-shade-2;
}

.blue-box {
    border-color: $da-lightpink;
    font-size: 12px;
    color: $da-gray-shade-2;
}

.custom-card {
    color: $da-white;
    background-color: $da-blue-shade;
    text-align: center;
    padding: 40px;
    font-size: 15px;
}

// header css
header {
    color: var(--theme-color, $da-black);
    background-color: var(--theme-background, $da-white);
    box-shadow: $da-black-shadow;
    .user-setting {
        .user-icon {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: $da-gray-shade-3;
        }
        .user-name {
            p {
                font-size: 10px;
                color: $da-gray-shade-4;
                font-weight: 600;
            }
            h6 {
                font-size: 12px;
                color: $da-lightpink;
                font-weight: 600;
            }
        }
    }

    .setting-icon {
        color: $da-lightpink;
    }

    #dropdown-toggle {
        &:checked {
            + label {
                + .dropdown-content {
                    display: block;
                }
            }
        }
    }
}

.wrap {
    text-align: center;
    span {
        font-size: 12px;
    }
}

.e-control-wrapper {
    background-color: var(--background-field,$da-white) !important;
    color: var(--border-color, $da-black) !important;
    input {
        &::placeholder {
            color: var(--border-color, $da-black) !important;
        }
    }
    .e-multi-select-wrapper {
        span {
            -webkit-text-fill-color: var(--border-color, $da-black) !important; 
            color: var(--border-color, $da-black) !important; 
        }
    }
    &.e-slider-container {
        padding: 0px 12px;
        .e-slider {
            height: unset !important;
            .e-range {
                top: calc(50% - 1px);
                background-color: $da-blue-shade;
                height: 5px;
            }
            .e-handle {
                top: calc(50% - 6px);
                background-color: $da-white;
                border: 1px solid $da-blue-shade;
                height: 15px;
                width: 15px;
                &.t-tab-handle {
                    &::after {
                        background-color: $da-blue-shade !important;
                    }
                }
                &::after {
                    background-color: $da-blue-shade !important;
                }
            }
            .e-slider-track {
                background-color: $da-blue-shade-1;
                height: 5px;
                border-radius: 10px;
            }
            .e-limits {
                background-color: transparent;
                height: 5px;
            }
            .e-tick-value {
                bottom: -9px !important;
            }
        }
        &.e-horizontal {
            height: unset;
        }
    }
}

.e-btn {
    &.e-flat {
        &.e-primary {
            color: $da-blue-shade !important;
            &:focus, &:hover {
                background: $da-blue-shade-1 !important;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
    box-shadow: inset 0 0 0 20px $da-gray-shade-2;
    transition: all 0.5s;
    &:hover {
        transition: all 0.5s;
        border-radius: 20px;
        box-shadow: inset 0 0 0 20px $da-blue-shade;
    }
}

:disabled {
    opacity: 0.6;
}

.action-btn {
    .edit-btn {
        color: $da-primary;
    }
}

.table-section {
    .e-toolbar {
        border-radius: 10px;
        box-shadow: 0 0 4px 1px $da-black-shadow-1;
        border-top: unset !important;
        padding: 10px 20px;
        border: unset;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        .e-toolbar-items {
            display: flex;
            justify-content: space-between;
            padding: 0;
            .e-toolbar-center {
                display: none;
                position: unset !important;
            }
            .e-toolbar-left {
                right: 0;
                left: unset !important;
            }
            .e-toolbar-right {
                display: flex;
                gap: 10px;
                .e-toolbar-item {
                    padding: 0 !important;
                    .e-input-group {
                        &.e-search {
                            padding: 4px 15px;
                            border: 1px solid var(--background-field ,$da-gray-shade-8);
                            border-radius: 40px;
                            box-sizing: border-box;
                            margin: 0;
                            background: var(--background-field, $da-white);
                            .e-input {
                              &.e-search {
                                background: var(--background-field, $da-white);
                                color: var(--theme-color, $da-black) !important;
                                &::placeholder {
                                  color: var(--theme-color, $da-black) !important;
                                }
                              }
                            }
                            @media screen and (max-width: 567px) {
                                width: 150px;
                            }
                            .e-input-group-icon {
                                color: $da-blue-shade;
                            }
                            &.e-input-focus {
                                &::before, &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                    .e-tbar-btn {
                        padding: 4px 15px;
                        border: 1px solid $da-gray-shade-11;
                        border-radius: 40px;
                        box-sizing: border-box;
                        margin: 0;
                        background-color: transparent;
                        cursor: pointer;
                        .e-tbar-btn-text {
                            position: relative;
                            color: $da-gray-shade-13;
                        }
                        .e-columnchooser-btn {
                            display: none;
                        }
                    }
                }
            }
            .e-scroll-right-nav {
                display: none;
            }
        }
    }

    // .e-gridpager {
    //     .e-pagercontainer {
    //         .e-numericcontainer {
    //             .e-spacing {
    //                 &.e-currentitem {
    //                     background-color: $da-lightpink;
    //                     &:hover {
    //                         background-color: $da-lightpink;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .e-gridpager {
        border: unset;
        margin-top: 30px;
        background-color: transparent;
        .e-pagercontainer {
            float: right;
            background-color: transparent;
            @media (max-width: 769px) {
                display: block !important;
            }
            .e-numericcontainer {
                .e-spacing {
                    border-radius: 5px;
                    border: 1px solid $da-gray-shade-14;
                    &.e-currentitem {
                        background-color: $da-lightpink;
                        &:hover {
                            background-color: $da-lightpink;
                        }
                    }
                }
            }
            .e-prev, .e-next {
                background-color: transparent;
                margin-top: 0 !important;
            }
            .e-first, .e-last {
                display: none;
            }
        }
        .e-parentmsgbar {
            display: none;
        }
        &.e-pager {
            .e-mfirst, .e-mprev, .e-mnext, .e-mlast {
                display: none;
            }
            div {
              &.e-icons {
                &.e-disable {
                  color: var(--theme-color, $da-black) !important;
                }
              }
            }
        }
    }
}


/* Comparison */
.gp-price-value {
    font-size: 2rem;
    color: $da-gp-base;

    &.no-impact {
        color: $da-primary;
    }
    &.gp-impact {
        color: $da-gp-impact;
    }
}

/* Tailwind */
.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--theme-color, $da-black);
}
.tracking-wider {
    color: var(--theme-color, $da-black);
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}


.e-slider-tooltip {
    &.e-tooltip-wrap {
        &.e-popup {
            background-color: $da-blue-shade  !important;
        }
    }
}

.e-css {
    &.e-checkbox-wrapper {
        .e-frame {
            &.e-check {
                background-color: $da-blue-shade !important;
            }
        }
    }
}

// LOGIN RESET FORGOT CSS

.login_section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 222px);
}

.login-box {
    width: 28%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media screen and (max-width: 767px) {
        width: 90%;
    }
    @media (min-width: 767px) and (max-width: 1200px) {
        width: 50%;
    }
    button {
        height: 40px;
        background-color: #0070c0;
        color: white;
        border-radius: 10px;
        text-transform: capitalize;
    }
    h2 {
        font-size: 22px;
        font-weight: 700;
    }
    .error-message{
        font-size: 12px;
        color: red;
    }
    .password-container {
        position: relative;
        display: inline-block;
        width: 100%;
        .icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: gray;
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 40px;
            background-color: transparent;
            z-index: 999;
        }

        .e-textbox {
            width: 100%;
            height: 40px;
            box-sizing: border-box;
            margin: 0;
        }

        .e-outline {
            box-shadow: none;
        }
    }
}

// sidebar component css
.side-bar-section {
    box-shadow: 0 0 4px 1px $da-black-shadow-1;
    .side-bar-btn {
        color: $da-black;
        &.active {
            background-color: $da-blue-shade;
            color: $da-white;
        }
    }
}

.user-icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $da-gray-shade-3;
}
.user-name {
    p {
        font-size: 10px;
        color: $da-gray-shade-4;
        font-weight: 600;
    }
    h6 {
        font-size: 12px;
        color: $da-lightpink;
        font-weight: 600;
    }
}

// user manage component css
.control-section {
    .btn-section {
        ejs-chiplist {
            &.active {
                background-color: $da-blue-shade;
                color: $da-white;
                .e-chip-icon {
                    color: $da-white;
                }
            }
        }
        .user-count {
            h2 {
                font-weight: 600;
                color: $da-primary;
                font-size: 22px;
            }
            h6 {
                font-size: 14px;
                font-weight: normal;
                color: $da-gray-shade-10;
            }
        }
    }
}

// header css
header {
    box-shadow: $da-black-shadow;
    z-index: 99;
    position: relative;
    .setting-icon {
        color: $da-lightpink;
    }
}

// user modal component css
.manage-user-modal {
    .e-popup {
        &.e-popup-open {
            &.e-dialog {
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%,-50%) !important;
            }
        }
    }
    ejs-dialog {
        .e-dlg-content {
            padding: 0 !important;
            background-color: transparent;
        }
        .form-section {
            box-shadow: 0 0 4px 1px $da-black-shadow-1;
        }
    }

    .input-container {
        position: relative;
        display: inline-block;
        width: 100%;
        .icon {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: $da-gray-shade-12;
        }

        .e-input {
            width: 100%;
            padding-left: 30px;
            height: 40px;
            border: 1px solid $da-gray-shade-11;
            border-radius: 5px;
            box-sizing: border-box;
            margin: 0;
            &:focus {
                border-color: $da-primary !important;
            }
        }

        .e-outline {
            box-shadow: none;
        }
    }

    .e-checkbox-wrapper {
        .e-icons {
            border-radius: 5px !important;
        }
        .e-checkbox {
            &:focus {
                + .e-frame {
                    &.e-check {
                        background-color: $da-primary !important;
                    }
                }
            }
        }
        .e-frame {
            &.e-check {
                background-color: $da-primary !important;
            }
            + .e-label {
                color: var(--theme-color, $da-black) !important;
                margin-left: 5px !important;
            }
        }
    }

    .e-switch-wrapper {
        .e-switch-on {
            background-color: $da-green-shade-1 !important;
        }
        .e-switch-handle {
            &.e-switch-active {
                background-color: $da-primary !important;
            }
        }
    }
}

.e-checkbox-wrapper {
    .e-icons {
        border-radius: 5px !important;
    }
    .e-checkbox {
        &:focus {
            + .e-frame {
                &.e-check {
                    background-color: $da-primary !important;
                }
            }
        }
    }
    .e-frame {
        &.e-check {
            background-color: $da-primary !important;
        }
        + .e-label {
            color: var(--theme-color, $da-black) !important;
            margin-left: 5px !important;
        }
    }
}

.add-edit-user {
    background-color: var(--card-color, $da-white);
    color: var(--theme-color, $da-black);
}

.jsoneditor-statusbar {
    display: none !important;
}
.json-editor-container {
    label {
        display: none;
    }
    .jsoneditor {
        border: none;
        &-menu {
            border: 1px solid rgba(0, 0, 0, 0.24);
            border-radius: 4px 4px 0px 0px;
            background-color: var(--table-header, #3883fa);
        }
        &-text-errors {
            border: none;
            tr.parse-error {
                background-color: transparent;
            }
            td{
                color: red;
                pre {
                    color: red;
                }
            }
        }
        &-text {
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.24);
            border-radius: 0px 0px 4px 4px;
            padding: 10px;
            background-color: var(--background-field, $da-white);
        }
        &-validation-errors {
            color: red;
        }
    }

}

// Two-Factor Css
.two-fa-authentication, .two-factor-config {
    .e-control-wrapper {
      border: 1px solid black !important;
      border-radius: 10px !important;
      padding: 7px !important;
      margin-top: 20px;
      &.e-input-focus {
        &::before,
        &::after {
          display: none;
        }
      }
    }
}

.e-dialog {
&.two-fa-dilog {
    max-height: 563px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%,-50%) !important;
    width: 600px !important;
    .backup-verification-code {
      width: 100% !important;
      margin: 0px !important;
      box-shadow: unset !important;
      padding: 0px !important;
    }
  }
}

.e-close {
  visibility: visible !important;
  transform: translateX(0) !important;
}
