$da-black: #000000;
$da-white: #ffffff;
$da-white-shade-1: #fafafa;
$da-gray-shade: #F0F0F0;
$da-gray-shade-1: #C0C0C0;
$da-gray-shade-2: #999EA2;
$da-gray-shade-3: #4d4d4d;
$da-gray-shade-4: #b1b1b1;
$da-gray-shade-5: #f9f9fe;
$da-gray-shade-6: #c2c2c2;
$da-gray-shade-7: #f6f6f6;
$da-gray-shade-8: #D0D0D0;
$da-gray-shade-9: #929292;
$da-gray-shade-10: #7D7E80;
$da-gray-shade-11: #cccccc;
$da-gray-shade-12: #888888;
$da-gray-shade-13: #A5A8AB;
$da-gray-shade-14: #D9D9D9;
$da-primary: #78B751;
$da-blue-shade: #0070C0;
$da-blue-shade-1: rgba(0, 112, 192, 0.24);
$da-lightpink: #7851A9;
$da-lightpink-1: rgba(120, 81, 169, 0.3);
$da-black-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
$da-black-shadow-1: rgba(191, 191, 191, 0.25);
$da-black-shadow-2: rgba(0, 0, 0, 0.1);
$da-green-shade: rgba(120,183,81,0.15);
$da-green-shade-1: rgba(120,183,81,0.6);
$da-black-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
$da-gp-base: #0070C0;
$da-gp-impact: #FD5E53;